import { postalRegexList } from "./postal";

export const validAccountNumber = value => {
  if (value.length > 3 && value.length < 18) return true
  return false
}

export const validRoutingNumber = value => {

  var i, n, t;

  // Check the length, it should be nine digits.

  if (value.length !== 9)
    return false;

  // First, remove any non-numeric characters.

  t = "";
  for (i = 0; i < value.length; i++) {
    var c = parseInt(value.charAt(i), 10);
    if (c >= 0 && c <= 9)
      t = t + c;
  }

  // Check the length now that , it should be nine digits.

  if (t.length !== 9)
    return false;

  // Now run through each digit and calculate the total.

  n = 0;
  for (i = 0; i < t.length; i += 3) {
    n += parseInt(t.charAt(i), 10) * 3 +
      parseInt(t.charAt(i + 1), 10) * 7 +
      parseInt(t.charAt(i + 2), 10);
  }

  // If the resulting sum is an even multiple of ten (but not zero),
  // the aba routing number is good.

  if (n !== 0 && n % 10 === 0)
    return true;
  else
    return false;
}

export const validCreditCard = value => {
  if (/[^0-9-\s]+/.test(value)) return false;
  if (value.replace(/\D/g, '').length < 13) return false;

  let nCheck = 0,
    bEven = false;
  value = value.replace(/\D/g, "");

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return (nCheck % 10) === 0;
}

export const notEmpty = value => {
  if (value.length > 0) return true

  return false
}

export const validAccountType = value => {
  if (value.toLowerCase() === 'checking' || value.toLowerCase() === 'savings') return true

  return false
}

export const validCvv = value => {
  let result = value.replace(/\D/g, '')


  return result.length === value.length && result.length > 2 && result.length < 5
}

export const validExp = value => {
  let [monthValue, yearValue] = value.split('/')
  if (!monthValue || !yearValue) return false

  let formattedYear = yearValue.length === 2 ? `20${yearValue}` : yearValue

  let month = monthValue.replace(/\D/g, '')
  let year = formattedYear.replace(/\D/g, '')

  const date = new Date()
  const MM = date.getMonth()
  const YY = date.getFullYear()

  let monthInt = parseInt(month)
  let yearInt = parseInt(year)

  if (year.length !== 4) return false

  if (monthInt === 0 || monthInt > 12) return false

  if (yearInt < YY) return false

  if (yearInt === YY) {
    if (monthInt <= MM) return false
  }

  return true
}

const validPhone = inputtxt => {
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  var phoneWithout = /^\d{10}$/;
  if (inputtxt.match(phoneno) || inputtxt.match(phoneWithout)) {
    return true
  }
  else {
    return false;
  }
}

const validEmail = mail => {
  var email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  if (mail.match(email)) {
    return true
  }
  else {
    return false
  }
}

export const validCashContact = value => {
  if (validPhone(value) || validEmail(value)) {
    return true
  }
  else {
    return false
  }
}

export const validPostalCode = input => {
  let result = false
  if(!input) return result
  postalRegexList.forEach(regex => {
    if (input.match(regex[1])) {
      result = true
    }
  })
  return result
}

const fieldTypes = [
    'credit-card',
    'card-number',
    'card-cvv',
    'card-exp',
    'card-name',
    'billing-line1',
    'billing-line2',
    'billing-city',
    'billing-state',
    'billing-zip',
    'cash-name',
    'cash-contact',
    'cash-zip',
    'account-name',
    'account-type',
    'account-number',
    'routing-number'
]

let validResponse = {
  'account-name': {
    check: notEmpty,
    error: 'Invalid account name'
  },
  'account-type': {
    check: validAccountType,
    error: 'Invalid account type'
  },
  'account-number': {
    check: validAccountNumber,
    error: 'Invalid account number'
  },
  'routing-number': {
    check: validRoutingNumber,
    error: 'Invalid routing number'
  },
  'card-number': {
    check: validCreditCard,
    error: 'Invalid card number'
  },
  'card-exp': {
    check: validExp,
    error: 'Invalid card exp'
  },
  'card-cvv': {
    check: validCvv,
    error: 'Invalid card security code'
  },
  'cash-name': {
    check: notEmpty,
    error: 'Invalid name for cash payment'
  },
  'cash-contact': {
    check: validCashContact,
    error: 'Invalid phone number or email for cash payment'
  },
  'billing-zip': {
    check: validPostalCode,
    error: 'Invalid postal code for payment'
  }
}

export const validCheck = (type, value, connected) => {
  const transactingTypes = ['card-number', 'account-number', 'cash-name']
  if (validResponse[type]) {
    let obj = validResponse[type]
    if (!obj.check(value)) {
      return [obj.error]
    }
  }
  else if (!fieldTypes.includes(type)) {
    return ['Not a valid field type']
  }
  else if (!connected && transactingTypes.includes(type)) {
    return [`${type} not connected to websockets`]
  }

  return []
}

export const formatCvv = value => {
  const digits = value.replace(/\D/g, '')
  return digits.substring(0, 4)
}

export const formatDate = value => {
  const dated = value.replace(/\D/g, '')
  if (dated.length === 0) return dated
  let month, year;

  if (dated.length === 1) {
    let val = parseInt(dated)
    if (val > 1 && val < 10) {
      month = "0" + val
    }
    else {
      month = val
    }
  }
  else if (dated.length === 2) {
    let val = parseInt(dated)
    if (val > 1 && val < 10) {
      month = "0" + val
    }
    else if (val > 9 && val < 13) {
      month = val
    }
    else {
      month = val === 0 ? "0" : val === 1 ? "01" : "0" + dated.charAt(0)
      year = dated.charAt(0) === "0" ? null : dated.substring(1)
    }
  }
  else {
    let val = parseInt(dated.substring(0, 2))
    if (val > 1 && val < 10) {
      month = "0" + val
      year = dated.charAt(0) === "0" ? dated.substring(2, 6) : dated.substring(1, 5)
    }
    else if (val > 9 && val < 13) {
      month = val
      year = dated.substring(2, 6)
    }
    else {
      month = dated.charAt(0) === "0" ? dated.substring(0, 2) : "0" + dated.charAt(0)
      year = dated.charAt(0) === "0" ? dated.substring(2, 6) : dated.substring(1, 5)
    }
  }

  return year ? `${month}/${year}` : `${month}`

}

export const formatNumbers = value => {
  return value.replace(/\D/g, '')
}

export const formatCard = value => {
  let val = value.replace(/\D/g, '')
  let firstTwo = val.substring(0, 2)
  let resultArray = []
  let result = ""
  if (firstTwo === "34" || firstTwo === "37") {
    let sections = [val.substring(0, 4), val.substring(4, 10), val.substring(10, 15)]
    sections.forEach(v => {
      if (v) resultArray.push(v)
    })
  }
  else {
    let sections = [val.substring(0, 4), val.substring(4, 8), val.substring(8, 12), val.substring(12, 16), val.substring(16, 20)]
    sections.forEach(v => {
      if (v) resultArray.push(v)
    })
  }

  resultArray.forEach((v, i) => {
    if (resultArray.length > (i + 1)) {
      result = result + v + " "
    }
    else {
      result = result + v
    }
  })

  return result

}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  }
  catch (e) {
    return false;
  }
  return true;
}

export const cardBrand = cur_val => {
  var sel_brand;
  // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
  // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

  //JCB
  let jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
  // American Express
  let amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
  // Diners Club
  let diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
  // Visa
  let visa_regex = new RegExp('^4[0-9]{0,}$'); //4
  // MasterCard
  let mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
  let maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
  //Discover
  let discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
  //6011, 622126-622925, 644-649, 65

  cur_val = cur_val.replace(/\D/g, '');

  // checks per each, as their could be multiple hits
  //fix: ordering matter in detection, otherwise can give false results in rare cases
  if (cur_val.match(jcb_regex)) {
    sel_brand = "jcb";
  } else if (cur_val.match(amex_regex)) {
    sel_brand = "american-express";
  } else if (cur_val.match(diners_regex)) {
    sel_brand = "diners_club";
  } else if (cur_val.match(visa_regex)) {
    sel_brand = "visa";
  } else if (cur_val.match(mastercard_regex)) {
    sel_brand = "mastercard";
  } else if (cur_val.match(discover_regex)) {
    sel_brand = "discover";
  } else if (cur_val.match(maestro_regex)) {
    if (cur_val[0] === '5') { //started 5 must be mastercard
      sel_brand = "mastercard";
    } else {
      sel_brand = "maestro"; //maestro is all 60-69 which is not something else, thats why this condition in the end
    }
  }

  return sel_brand;
}

// const STYLES_MODEL = {
//   default: {
//     border: "none",
//     fontSize: "16px",
//     fontFamily: "Monaco, Letter Gothic Std, Andale Mono",
//     width: "100%",
//     background: "transparent",
//     padding: "10px 5px"
//   },
//   success: {
//     color: "#333a56",
//     border: "none",
//     fontSize: "16px",
//     fontFamily: "Monaco, Letter Gothic Std, Andale Mono",
//     width: "100%",
//     background: "transparent",
//     padding: "10px 5px"
//   },
//   error: {
//     color: "#d9534f",
//     border: "none",
//     fontSize: "16px",
//     fontFamily: "Monaco, Letter Gothic Std, Andale Mono",
//     width: "100%",
//     background: "transparent",
//     padding: "10px 5px"
//   },
//   placeholder: {
//     color: "red",
//     "font-style": "italic"
//   }
// };

// const fieldsModel = [
//   {
//     id: "pt-account-number",
//     field: {
//       type: "account-number",
//       placeholder: "Account Number",
//       autoComplete: ""
//     },
//     style: STYLES_MODEL
//   },
//   {
//     id: "pt-account-type",
//     field: {
//       type: "account-type",
//       placeholder: "Account Type",
//       autoComplete: ""
//     },
//     style: STYLES_MODEL
//   },
//   {
//     id: "pt-bank-code",
//     field: {
//       type: "bank-code",
//       placeholder: "Bank Code",
//       autoComplete: ""
//     },
//     style: STYLES_MODEL
//   }
// ];
